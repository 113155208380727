
import React from 'react';
import { TextInput, Edit, SimpleForm, SelectInput, required, useTranslate, number, minLength, maxLength } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.location.edit')}: {record ? `${record.name}` : ''}</span>;
};

const validateLatVal = (value, allValues) => {
    if (value <= -90 || value > 90) {
        return 'Doit être en [-90, 90]';
    }
    return [];
  };
  
  const validateLat = [required(), number(), validateLatVal];
  
  const validateLngVal = (value, allValues) => {
    if (value < -180 || value > 180) {
        return 'Doit être en [-180, 180]';
    }
    return [];
  };
  
  const validateLng = [required(), number(), validateLngVal];
const validatePostalCodeVal = (value, allValues) => {
    if (value < 1000 || value > 95999) {
        return 'Doit être en [01000, 95999]';
    }
    return [];
  };
  
  const validatePostalCode = [required(), minLength(5), maxLength(5), validatePostalCodeVal];

const EditLocation = ({classes, permissions, ...props })  => (
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         <SimpleForm redirect="list" 
                toolbar={<CustomToolbar permissions={permissions} />}
                defaultValue={{ role: 'Admin' }}>
                
            <TextInput source="name" label="Nom" validate={required()} fullWidth/>
            <TextInput source="address" label="Adresse" validate={required()} multiline fullWidth/>
            <TextInput source="postal_code" label="Code postal"/>
            <SelectInput source="type" label="Type" validate={required()} choices={locationTypes}/>
            <TextInput source="lat" label="Latitude" validate={validateLat}/>
            <TextInput source="lng" label="Longitude" validate={validateLng}/>
            {/* <DateInput source="birth_day" label="Date de naissance" locales="fr-FR"/> */}
            {/* <DateInput source="birth_day" label="Date de naissance" 
                autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}

        </SimpleForm>
    </Edit>
);

export default EditLocation;