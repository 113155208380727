import React, { Fragment } from 'react';
import { ReferenceInput, ReferenceField, AutocompleteInput, Filter, TextInput, SelectInput, useTranslate, List, Datagrid, TextField, DateField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { OrderTypeField, TimeSlotTextField, OrderStatusField, FullNameClientField, FormattedDateField, FullNameClientWithTypeField, DeliveryAddressField } from '../../control/field/index'
import { orderRunningStatusValues } from '../../control/field/order-status-field'
const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_unassigned.list')}</span>;
};

const ProductField = ({ record = {} }) => {
    // const options = JSON.parse(record.options_new);
    console.log("Options " + record.options);
    console.log("Options new " + record.options_new);

    let options;
    if (record.options_new === '' || record.options_new === null || record.options_new === 'undefined' || typeof (record.options_new) === 'undefined')
        options = JSON.parse(record.options);
    else
        options = JSON.parse(record.options_new);

    return (
        <div >
            <div>
                {options.map((option, index) => {
                    if (index === options.length - 1)
                        return <span>{option.name} : {option.quantity}</span>
                    return <span>{option.name} : {option.quantity},<br /></span>
                })}
            </div>
            {/* <ValidityDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>

    )
}


ProductField.defaultProps = {
    addLabel: true,
};

// const PaymentStatusField = ({ record = {}, paymentStatusField }) => {

//     let paymentStatus = record[paymentStatusField];
//     var paymentStatusText = paymentStatus === "1" ? "Payé" : (paymentStatus === "0" ? "Non payé" : (paymentStatus === "-2" ? "Erreur du paiement" : (paymentStatus === "4" ? "En attente 3DS" : (paymentStatus === "5" ? "En attente pour la capture" : "Inconnu"))));
//     return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
//     </div>;
// };
const PaymentStatusField = ({ record = {}, paymentStatusField = "payment_status", paymentField = "payment" }) => {
    let paymentStatus = record[paymentStatusField];


    if (paymentStatus === "-2") {
        let payment = JSON.parse(record[paymentField]);
        console.log("Payment: " + JSON.stringify(payment));

        let lastPaymentError = payment['data']['object']['last_payment_error'];
        console.log("Last payment error: " + JSON.stringify(lastPaymentError));

        // console.log("Payment id: " + payment['id']);
        let code = lastPaymentError['code'];
        if (code === "card_declined") {
            let declinedCode = payment['data']['object']['last_payment_error']['decline_code'];
            let error = "Autre";
            if (declinedCode === "expired_card")
                error = "Carte expirée";
            else
                if (declinedCode === "insufficient_funds")
                    error = "Fonds insuffisants";
                else if (declinedCode === "issuer_not_available")
                    error = "L'émetteur de la carte n'a pas pu être contacté";


            var paymentStatusText = "Erreur : " + error;
            if (error === "Autre")
                paymentStatusText = "Erreur : " + error + " (Code : " + declinedCode + ")";

            return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
            </div>;
        }
        else if (code === "payment_intent_authentication_failure") {
            var paymentStatusText = "Erreur : Echec d'authentification";

            return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
            </div>;

        } else {
            var paymentStatusText = "Erreur : Autre (Code: " + code + ")";

            return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
            </div>;
        }

    }

    var paymentStatusText = paymentStatus === "1" ? "Payé" : (paymentStatus === "0" ? "Non payé" : (paymentStatus === "-2" ? "Erreur du paiement" : (paymentStatus === "4" ? "En attente 3DS" : (paymentStatus === "5" ? "En attente pour la capture" : "Inconnu"))));
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
    </div>;
};

PaymentStatusField.defaultProps = {
    addLabel: true,
};

// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Nº de commande" source="order_number" alwaysOn resettable />
        {/* <ReferenceInput label="Email client" source="client_id" reference="client" perPage={99999} alwaysOn resettable
            emptyText="Tous"
        >
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput> */}
        <TextInput label="Nom du client" source="to_head_name" alwaysOn resettable />

        {/* <ReferenceInput label="Email chauffeur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <AutocompleteInput optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput> */}
        {/* <SelectInput label="État" source="status" choices={orderRunningStatusValues} 
        optionText={<OrderRunningStatusFilterField />}
        alwaysOn/> */}
        {/* <TextInput label="Adresse de départ" source="from_address" resettable/> */}
        <TextInput label="Adresse de livraison" source="to_address" alwaysOn resettable />
        <TextInput label="Code postal de livraison" source="to_postal_code" alwaysOn resettable />

        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);


const ListOrderUnassigned = (props) => (
    <List title={<Title />} {...props}
        filters={<ListFilter />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="order_number" label="Nº de commande" />
            <FormattedDateField source="pick_time" format="format_date" label="Date de livraison" />
            <TimeSlotTextField label="Créneau de livraison" />

            {/* <ActivityTypeTextField label="Type de service"/> */}
            {/* <FullNameClientField label="Client" /> */}
            <FullNameClientWithTypeField label="Client" />
            {/* <TextField source="from_address" label="Adresse de départ"/> */}
            {/* <TextField source="to_address" label="Adresse de livraison" /> */}
            <DeliveryAddressField label="Adresse de livraison" />
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            <ProductField label="Produit" />
            <TextField source="total" label="Prix(€)" />
            <PaymentStatusField paymentStatusField="payment_status" label="Etat du paiement" />
            {/* <ReferenceField label="Annulée par" source="canceled_by"
                reference="driver" link="" perPage={9999999}>
                <TextField source="email" />
            </ReferenceField> */}
            {/* <TextField source="mode" label="Mode" /> */}
            <OrderTypeField source="order_type" label="Type de commande" />
            <FormattedDateField source="create_time" format="format_date_time" label="Date de commande" />


        </Datagrid>
    </List>
);

export default ListOrderUnassigned;

