import React, {Fragment} from 'react';
import { List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} />
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.region.list')}</span>;
};

const TypeTextField = ({ source, record = {}, label}) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}
    
TypeTextField.defaultProps = {
    addLabel: true,
};

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Nom" source="name" alwaysOn resettable/>
        {/* <TextInput label="Adresse" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn choices={locationTypes}
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
        /> */}
        
    </Filter>
);

const ListLocation = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} perPage={50}
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Nom"/>
            
        </Datagrid>
    </List>
);

export default ListLocation;

