import React, {Fragment} from 'react';
import { CardActions, ReferenceInput, AutocompleteInput, CreateButton, ReferenceField, List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import { Route } from 'react-router';
import CreateVehicleBrand from './create';
import {activityTypes, ACTIVITY_TYPE_DELIVERY, ACTIVITY_TYPE_TOURISM, ACTIVITY_TYPE_UTILITY} from '../../util/constant'
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import {ValidateField, FormattedDateField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

const ListActions = ({ basePath }) => (
       <CardActions>
           <CreateButton basePath={basePath} />
       </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.list')}</span>;
};

const ActivityTypeTextField = ({ source, record = {}, label}) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if(activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    // activityTypes.forEach(activityType => {
    //     if(activityType.id == record.activity_type)
    //     return <span class="MuiTypography-root MuiTypography-body2">{typeof(activityType) !== 'undefined' ? activityType.name : record.activity_type}</span>;
    // });
    return <span/>;
    // const activityType = activityTypes[record.activity_type];
    // return <span class="MuiTypography-root MuiTypography-body2">{typeof(activityType) !== 'undefined' ? activityType.name : record.activity_type}</span>;
}
    
ActivityTypeTextField.defaultProps = {
    addLabel: true,
};

const statusList = [
    {id: 0, name: 'Non-vérrouillé'},
    {id: 1, name: 'Vérrouillé'}
];

const ListFilter = ({ permissions, ...props })  => {
    // const { permissions } = usePermissions();
    return <Filter {...props}>
        <TextInput label="Immatriculation" source="plate_number" alwaysOn resettable/>

        {permissions === 'Admin' &&
        <SelectInput label="Type de service" source="activity_type" alwaysOn choices={activityTypes}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'
        />}
        <ReferenceInput label="Gamme" source="vehicle_type_id" reference="vehicle_type" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <AutocompleteInput optionText="name"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        <ReferenceInput label="Marque" source="brand_id" reference="vehicle_brand" perPage={99999} emptyText="Tous" resettable> 
            <AutocompleteInput optionText="name"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        <ReferenceInput label="Modèle" source="model_id" reference="vehicle_model" perPage={99999} emptyText="Tous" resettable> 
            <AutocompleteInput optionText="name"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        <ReferenceInput label="Email chauffeur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <AutocompleteInput optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        
    </Filter>;
};

const ListVehicle = ({permissions, ...props}) => (
    <React.Fragment>
        <List title={<Title/>} {...props} actions={<ListActions/>}
            filters = {<ListFilter permissions={permissions} />} 
            exporter={false}>
             <Datagrid rowClick="edit">
                <TextField source="plate_number" label = "Immatriculation"/>
                <ActivityTypeTextField label="Type de service"/>
                <ReferenceField label="Gamme" source="vehicle_type_id" reference="vehicle_type" link="" perPage={99999}>
                    <TextField source="name" />
                </ReferenceField>
                {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand" link="" perPage={99999}>
                    <TextField source="name" />
                </ReferenceField> */}
                <TextField source="brand_name" label = "Marque"/>
                {/* <ReferenceField label="Modèle" source="model_id" reference="vehicle_model" link="" perPage={99999}>
                    <TextField source="name" />
                </ReferenceField> */}
                <TextField source="model_name" label = "Modèle"/>
                {/* <FormattedDateField source="circulation_date" label="Date de première mise en circulation"  locales="fr-FR"/> */}
                <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="" perPage={99999}>
                    <TextField source="email" />
                </ReferenceField>
                {/* <ValidateField source="is_locked" label="Verrouillée"/> */}
                {/* <EditButton/> */}
            </Datagrid>
        </List>
        {/* <Route
           path="/vehicle_brand/create"
           render={() => (
               <CreateVehicleBrand {...props} />
           )} */}
    
    </React.Fragment>
);

export default ListVehicle;

