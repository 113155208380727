
import React from 'react';
import { number, FormDataConsumer, AutocompleteInput, NumberInput, Pagination, TextField, ReferenceManyField, Datagrid, FormTab, TabbedForm, ReferenceInput, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { EditButton, ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {orderTypes} from '../../util/constant'
import {activityTypes, orderActivityTypes} from '../../util/variant'
import { ValidateField } from '../../control/field';
// import {AddPriceTimeButton} from '../../button/field';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const Title = ({ record }) => {
    // var string = "";
    // if(record){
    //     const vehicleTypeName = record ? record.vehicle_type_name : '';
    //     const orderType = record ? record.order_type : 0;
    //     if(orderType == OrderType.IMMEDIATE)
    //         string = vehicleTypeName + " - Immédiate";
    //     else
    //         string = vehicleTypeName + " - Réservation à l'avance";
    // }
    const translate = useTranslate();
    // return <span>{translate('resources.price.show')} pour: {record ? `${string}` : ''}</span>;
    return <span>{translate('resources.price.show')}</span>;
    
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];

// var activityTypes = [];
// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

const EditPrice = ({permissions, ...props })  => {
    const classes = useStyles();
    return (
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         
                    <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                    <ReferenceInput source="vehicle_sub_type_id" perPage={99999} className={classes.inlineBlock}
            reference="vehicle_sub_type" label="Gamme">
                <SelectInput optionText="name"/>
            </ReferenceInput>

            {/* <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/> */}
            
            {/* <TextInput source="min" label="Minimum(€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            <TextInput source="base" label="Base(€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            <div/>
            <TextInput source="per_km" label="Par km(€)" validate={validatePriceAdditional} className={classes.inlineBlock}/> */}
            <TextInput source="duration" label="Durée(heure)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            <TextInput source="per_hour" label="Par heure(DZD)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            
                       
                    </SimpleForm>
               
    </Edit>
)};

export default EditPrice;