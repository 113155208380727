import React from 'react';
import Typography from '@material-ui/core/Typography';
import { DateField, Labeled } from 'react-admin';
import {
    Status, DataStatus, FALSE, TRUE, USER_TYPE_AUTO_ENTERPRENER, ANIMAL_TYPE_FREE, ANIMAL_TYPE_CAGE,
    ACTIVITY_TYPE_ATR, ACTIVITY_TYPE_VTC, ORDER_ACTIVITY_TYPE_ANIMAL_ONLY, ORDER_ACTIVITY_TYPE_PASSENGER_ONLY, ORDER_ACTIVITY_TYPE_PASSENGER_WITH_ANIMAL, USER_TYPE_DRIVER_COMPANY,
    USER_TYPE_CLIENT
} from '../../util/constant'
import { DocDateEditButton } from '../button/index'
import { ImageViewer } from '../viewer/index'
import { makeStyles } from '@material-ui/core/styles';
import { countries } from '../../util/constant';
import AvatarViewer from '../viewer/avatar-viewer';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', }

});

export const DocNameField = ({ record, label }) => {
    // const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    // if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
    //     const nameObj = JSON.parse(record.name);
    //     // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
    //     // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
    //     return <div><span>{nameObj.fr}</span></div>;
    // }
    if (typeof (record) !== 'undefined' && typeof (record.doc_name) !== 'undefined') {
        // const nameObj = JSON.parse(record.name);
        // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
        // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
        return <div><span>{record.doc_name}</span></div>;
    }
    return <div />;

}

DocNameField.defaultProps = {
    addLabel: true
}

export const FileCountField = ({ record, label }) => {
    const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    if (typeof (record) !== 'undefined' && typeof (record.name) !== 'undefined') {
        return <div><span className={classes.colorValue}>{record.files.length}</span></div>
    }

    return <div><span className={classes.colorValue}>0</span></div>

}

FileCountField.defaultProps = {
    addLabel: true
}

export const DriverCompanyTypeField = ({ record = {}, label }) => {
    return <div>
        <Typography variant="body2">
            {record.user_type == USER_TYPE_AUTO_ENTERPRENER ? 'Auto-entrepreneur' : 'Autre'}
        </Typography>
    </div>;
}

DriverCompanyTypeField.defaultProps = {
    addLabel: true,
};

export const DocStatusReasonField = ({ record = {}, label }) => {
    const classes = useStyles();
    const statusData = record.status_data;
    if (typeof (statusData) !== 'undefined' && statusData !== null && statusData !== '') {
        const statusObj = JSON.parse(statusData);
        // return <span>{statusObj.reason}</span>;
        return <Typography variant="body2" className={classes.colorPrimary}>{statusObj.reason}</Typography>
    }
    //     return <Typography variant="body2">
    //     {statusData.reason}
    // </Typography>
    return <span></span>;
}

DocStatusReasonField.defaultProps = {
    addLabel: true,
};

export const ClientDataField = ({ record = {}, source, label }) => {
    const client = record.client;
    if (typeof (client) !== 'undefined' && client !== null && client !== '') {
        const clientObj = JSON.parse(client);
        return <Typography variant="body2">{clientObj[source]}</Typography>
    }

    return <span></span>;
}

ClientDataField.defaultProps = {
    addLabel: true,
};

export const DriverDataField = ({ record = {}, source, label }) => {
    const driver = record.driver;
    if (typeof (driver) !== 'undefined' && driver !== null && driver !== '') {
        const driverObj = JSON.parse(driver);
        return <Typography variant="body2">{driverObj[source]}</Typography>
    }

    return <span></span>;
}

DriverDataField.defaultProps = {
    addLabel: true,
};


export const ValidityDateField = ({ record = {}, label }) => {
    const classes = useStyles();
    return record.date_id !== "" ? <div><br />
        <Typography variant="body2" className={classes.noColorLabel}>
            Date de validité: {record.date !== "" ? <DateField className={classes.colorValue} source="date" record={record} label="" locales="fr-FR" /> : '--'}
            {/* <DocDateEditButton record={record}/> */}
        </Typography><br />
    </div> : '';
}

export const PhotoField = ({ record = {}, source, label, round = '50%', width = '60', height = '60' }) => {
    // const classes = useStyles();
    return <AvatarViewer files={[]} fileUrl={record[source]} title={label} width={width} height={height} round={round} />

}

export const DriverCompanyTypeFilterField = ({ record }) => {
    return <span>{record.id == USER_TYPE_AUTO_ENTERPRENER ? 'Personne physique' : 'Société'}</span>;
};

export const ActivityTypeField = ({ record = {}, label }) => {
    return <div>
        <Typography variant="body2">
            {record.activity_type == ACTIVITY_TYPE_VTC ? 'VTC' : "Transport léger  des  marchandises"}
        </Typography>
    </div>;
}

ActivityTypeField.defaultProps = {
    addLabel: true,
};

export const ActivityTypeFilterField = ({ record }) => {
    return <span>{record.id == ACTIVITY_TYPE_VTC ? 'VTC' : 'Transport léger  des  marchandises'}</span>;
};

export const ClientTypeField = ({ record = {}, label }) => {
    return <div>
        <Typography variant="body2">
            {record.client_type == 0 ? 'Tous les types' : (record.client_type == USER_TYPE_CLIENT ? "Particuliers" : "Professionnels")}
        </Typography>
    </div>;
}

ClientTypeField.defaultProps = {
    addLabel: true,
};


export const OrderActivityTypeField = ({ record = {}, label }) => {
    return <div>
        <Typography variant="body2">
            {record.activity_type == ORDER_ACTIVITY_TYPE_PASSENGER_ONLY ? 'Passagers tout seul' : (record.activity_type == ORDER_ACTIVITY_TYPE_PASSENGER_WITH_ANIMAL ? "Passagers avec un animal" : "Animaux non accompagnés")}
        </Typography>
    </div>;
}

OrderActivityTypeField.defaultProps = {
    addLabel: true,
};

export const AnimalTypeField = ({ record = {}, label }) => {
    return <div>
        <Typography variant="body2">
            {record.animal.type === ANIMAL_TYPE_FREE ? 'Chien en laisse' : "Animal en cage/panier"}
        </Typography>
    </div>;
}

AnimalTypeField.defaultProps = {
    addLabel: true,
};


export const FullNameClientField = ({ record = {}, }) => (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {/* {record.c_first_name}&nbsp;{record.c_last_name} */}
        {record.to_head_name}
    </div>
);
FullNameClientField.defaultProps = {
    addLabel: true,
};

export const FullNameClientWithTypeField = ({ record = {}, }) => {
    console.log("FullNameClientWithTypeField: " + JSON.stringify(record));
    const type = record.c_user_type;
    const typeStr = type === USER_TYPE_CLIENT || type === USER_TYPE_CLIENT.toString() ? "Particulier" : "Professionel";
    // return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
    //     {record.c_first_name}&nbsp;{record.c_last_name}<br />({typeStr})
    // </div>
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {record.to_head_name}<br />({typeStr})
    </div>
};
FullNameClientWithTypeField.defaultProps = {
    addLabel: true,
};

export const DeliveryAddressField = ({ record = {}, }) => {
    console.log("DeliveryAddressField: " + JSON.stringify(record));
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {record.to_address}, {record.to_postal_code}
    </div>
};
DeliveryAddressField.defaultProps = {
    addLabel: true,
};


export const FullNameDriverField = ({ record = {}, }) => {
    if (record.d_company_name !== "")
        return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            {record.d_company_name}
        </div>;
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {record.d_first_name}&nbsp;{record.d_last_name}
    </div>;
};
FullNameDriverField.defaultProps = {
    addLabel: true,
};

export const AnimalField = ({ record = {}, label }) => {
    var animal = record.extra.animal;
    if (typeof (animal) === 'undefined' || animal === 'undefined') return <div />;
    var cageHeight = animal.cage_height;
    var cageWidth = animal.cage_width;
    var cageLength = animal.cage_length;
    var cageSize = animal.cage_size;
    var type = animal.type;
    // return <Typography variant="body2">
    //         {type} - {cageHeight} - {cageWidth} - {cageLength} - {cageSize}
    //     </Typography>;
    // var isNotNull = typeof(cageHeight) !== "undefined" && cageHeight !== "undefined" && cageHeight !== null;
    return type === ANIMAL_TYPE_CAGE ? <div>
        {/* <Labeled  label="Taille de la cage/panier" styles={{'fontWeight': 'bold'}}> */}
        <Labeled label="Race">
            {animal.race}
        </Labeled>
        <br />
        <Labeled label="Hauteur de la cage/panier(cm)">
            <Typography variant="body2">
                {cageHeight}
            </Typography>
        </Labeled>
        <Labeled label="Largeur de la cage/panier(cm)">
            <Typography variant="body2">
                {cageWidth}
                {/* XXX */}
            </Typography>
        </Labeled>
        <Labeled label="Longeur de la cage/panier(cm)">
            <Typography variant="body2">
                {cageLength}
            </Typography>
        </Labeled>
        <Labeled label="Taille totale de la cage/panier(cm)">
            <Typography variant="body2">
                {cageSize}
            </Typography>
        </Labeled>
        {/* </Labeled> */}
    </div> : <div />;
}

AnimalField.defaultProps = {
    addLabel: false,
};

export const CountryTextField = ({ source, record = {}, label }) => {
    for (let country of countries) {
        if (country.id == record[source])
            return <span class="MuiTypography-root MuiTypography-body2">{typeof (country) !== 'undefined' ? country.name : record[source]}</span>;
    }
    // return {countries.filter(country => country.id == record[source])))
    //        .map(name => {
    //                 return <li key={name}>{name} </li>
    //               })};

    // countries.map(country => {
    //     if(country.id == record[source])
    //         return <span class="MuiTypography-root MuiTypography-body2">{typeof(country) !== 'undefined' ? country.name : record[source]}</span>;
    //     return <span/>;
    // });

    return <span />;
    // const country = countries[record[source]];
    // return <span class="MuiTypography-root MuiTypography-body2">{typeof(country) !== 'undefined' ? country.name : record[source]}</span>;
}

CountryTextField.defaultProps = {
    addLabel: true,
};

export const TimeSlotTextField = ({ source, record = {}, label }) => {
    // if(record === 'undefined' || record.every_day === 'undefined'
    // || typeof(record.every_day)=== 'undefined') return <span></span>;

    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const fromTime = record.time_from;
    const toTime = record.time_to;
    const pickTime = record.pick_time;
    // console.log("Repeat days: "  + days);
    if (fromTime === '' || toTime === '' ||
        fromTime === 'undefined' || typeof (fromTime) === 'undefined' ||
        toTime === 'undefined' || typeof (toTime) === 'undefined')
        return <span></span>;
    return <span class="MuiTypography-root MuiTypography-body2">{fromTime}h - {toTime}h</span>;


}

TimeSlotTextField.defaultProps = {
    addLabel: true,
};