import React, { Fragment } from 'react';
import { Filter, TextInput, ShowButton, DateInput, ReferenceInput, AutocompleteInput, ReferenceField, SelectInput, List, Datagrid, TextField, DateField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { OrderTypeField, OrderStatusField, FormattedDateField, FullNameDriverField, FullNameClientField, TimeSlotTextField, FullNameClientWithTypeField, DeliveryAddressField } from '../../control/field/index'
import { activityTypes, status } from '../../util/constant';
import frLocale from "date-fns/locale/fr";
import DownloadButton from '../../control/button/download-button';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

// const FullNameCientField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.c_first_name}&nbsp;{record.c_last_name}
//     </div>
// );

// const FullNameDriverField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.d_first_name}&nbsp;{record.d_last_name}
//     </div>
// );

const DownloadFileButton = ({ source, record = {}, label }) => {

    if (record.invoice_url === null || record.invoice_url === "undefined" || record.invoice_url === "")
        return <div />

    // console.log("Invoice url: " + record.invoice_url);
    return <DownloadButton fileUrl={record.invoice_url} fileName={"factures"} />;
    // return <DownloadButton onClick={(e) => alert("Fichier facture n'est pas encore disponible")} fileName={record.file_name}/>;
}

DownloadFileButton.defaultProps = {
    addLabel: true,
};


const Title = ({ record }) => {
    return <span>Commandes terminées</span>;
};

const ActivityTypeTextField = ({ source, record = {}, label }) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if (activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    return <span />;

}

const ProductField = ({ record = {} }) => {
    const options = JSON.parse(record.options_new);

    return (
        <div >
            <div>
                {options.map((option, index) => {
                    if (index === options.length - 1)
                        return <span>{option.name} : {option.quantity}</span>
                    return <span>{option.name} : {option.quantity},<br /></span>
                })}
            </div>
            {/* <ValidityDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>

    )
}


ProductField.defaultProps = {
    addLabel: true,
};

// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Nº de commande" source="order_number" alwaysOn resettable />
        {/* {permissions === 'Admin' && <SelectInput label="Type de service" source="activity_type" alwaysOn choices={activityTypes}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'/>} */}

        <ReferenceInput label="Mode de paiement" source="payment_type_id" reference="payment_type" perPage={99999} alwaysOn emptyText="Tous">
            <SelectInput optionText="name"

            />
        </ReferenceInput>
        {/* <ReferenceInput label="Email client" source="client_id" reference="client" perPage={99999} alwaysOn resettable
            emptyText="Tous"
        >
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />

        </ReferenceInput> */}
        <SelectInput label="État" source="status" alwaysOn choices={status}
            allowEmpty emptyText="Tous" emptyValue='0' initialValue='0' />
        {/* <TextInput label="Nom du client" source="client_name" alwaysOn resettable /> */}
        <TextInput label="Nom du client" source="to_head_name" alwaysOn resettable />
        <ReferenceInput label="Email livreur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable>
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput>
        {/* <TextInput label="Adresse de départ" source="from_address" alwaysOn resettable/> */}
        <TextInput label="Adresse de livraison" source="to_address" alwaysOn resettable />
        <TextInput label="Code postal de livraison" source="to_postal_code" alwaysOn resettable />
        <DateInput label="Date de début" source="from_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }} />
        <DateInput label="Date de fin" source="to_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }} />

        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);


const ListOrderFinished = (props) => (
    <List title={<Title />} {...props} filterDefaultValues={{ 'from_date': '2023-01-01' }}
        filters={<ListFilter />} sort={{ field: 'create_time', order: 'DESC' }}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="" >
            <ShowButton label="" />
            <FullNameDriverField label="Livreur" />
            <TextField source="order_number" label="Nº de commande" />
            <FormattedDateField source="pick_time" format="format_date" label="Date de livraison" />
            <TimeSlotTextField label="Créneau de livraison" />
            {/* <FullNameClientField label="Client" /> */}
            <FullNameClientWithTypeField label="Client" />
            {/* <TextField source="to_address" label="Adresse de livraison" /> */}
            <DeliveryAddressField label="Adresse de livraison" />
            {/* <FormattedDateField source="create_time" format="format_date_time" label="Date/heure" /> */}

            {/* <ActivityTypeTextField label="Type de service"/> */}
            {/* <OrderTypeField source="order_type" label="Type de commande" /> */}

            <ProductField label="Produit" />
            <TextField source="total" label="Prix(€)" />
            <ReferenceField label="Mode de paiement" source="payment_type_id"
                reference="payment_type" link="">
                <TextField source="name" />
            </ReferenceField>


            {/* <OrderActivityTypeField source="order_type" label= "Type d'activité"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            {/* <TextField source="from_address" label="Adresse de départ"/> */}

            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}



            <OrderStatusField source="status" label="État" />
            <DownloadFileButton label="Facture client" />

        </Datagrid>
    </List>
);

export default ListOrderFinished;

