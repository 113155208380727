import Create from './create' 
import Edit from './edit' 
import List from './list' 
import Show from './show' 
import VehicleTypeIcon from '../../res/icon/vehicle-type-icon'
export default{
    // create: Create,
    edit: Edit, 
    list: List,
    // show: Show,
    icon: VehicleTypeIcon,
}