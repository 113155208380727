
import React, { Link } from 'react';
import { Datagrid, ReferenceField, ReferenceManyField, SimpleShowLayout, TextField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { locationDict } from '../../util/variant'
import { ClientField, OrderStatusField, FormattedDateField, OrderTypeField, TimeSlotTextField, FullNameClientField, FullNameDriverField } from '../../control/field/index'
import { RemoveButton } from '../../control/button/index'

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color: '#F4004E',
    },
});

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <AssignButton/> */}
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_unassigned.show')}:#{record ? `${record.order_number}` : ''}</span>;

};

const PaymentStatusField = ({ record = {}, paymentStatusField = "payment_status", paymentField = "payment" }) => {
    let paymentStatus = record[paymentStatusField];
    if (paymentStatus === "-2") {

        let payment = JSON.parse(record[paymentField]);
        let lastPaymentError = payment['data']['object']['last_payment_error'];
        console.log("Last payment error: " + JSON.stringify(lastPaymentError));
        // let payment = record[paymentField];
        // console.log("Payment id: " + payment['id']);
        let code = lastPaymentError['code'];
        if (code === "card_declined") {
            let declinedCode = payment['data']['object']['last_payment_error']['decline_code'];
            let error = "Autre";
            if (declinedCode === "expired_card")
                error = "Carte expirée";
            else
                if (declinedCode === "insufficient_funds")
                    error = "Fonds insuffisants";
                else if (declinedCode === "issuer_not_available")
                    error = "L'émetteur de la carte n'a pas pu être contacté";


            var paymentStatusText = "Erreur : " + error;
            if (error === "Autre")
                paymentStatusText = "Erreur : " + error + " (Code : " + declinedCode + ")";

            return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
            </div>;
        }
        else if (code === "payment_intent_authentication_failure") {
            var paymentStatusText = "Erreur : Echec d'authentification";

            return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
            </div>;

        } else {
            var paymentStatusText = "Erreur : Autre (Code: " + code + ")";

            return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
            </div>;
        }

    }

    var paymentStatusText = paymentStatus === "1" ? "Payé" : (paymentStatus === "0" ? "Non payé" : (paymentStatus === "-2" ? "Erreur du paiement" : (paymentStatus === "4" ? "En attente 3DS" : (paymentStatus === "5" ? "En attente pour la capture" : "Inconnu"))));
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <span style={{ fontSize: 13 }}>{paymentStatusText}</span>
    </div>;
};

PaymentStatusField.defaultProps = {
    addLabel: true,
};

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderUnassigned = ({ staticContext, ...props }) => {
    const classes = useStyles();
    return (
        <Show title={<Title />} {...props} actions={<ShowActions />}>

            <SimpleShowLayout>
                <OrderStatusField source="status" label="État" className={classes.colorPrimary} />
                <FormattedDateField source="create_time" format="format_date_time" label="Date/heure" />
                <TextField source="order_number" label="Nº de commande" />
                {/* <ActivityTypeTextField label="Type de service"/> */}
                <OrderTypeField source="order_type" label="Type de commande" />
                <FormattedDateField source="pick_time" format="format_date" label="Date de livraison" />
                <TimeSlotTextField label="Créneau de livraison" />
                <ReferenceField label="Type de paiement" source="payment_type_id"
                    reference="payment_type" link="">
                    <TextField source="name" />
                </ReferenceField>
                {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" 
            reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField> */}
                {/* <OrderActivityTypeField source="order_type" label= "Type d'activité"/> */}
                {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
                <FullNameClientField label="Client" />
                {/* <TextField source="from_address" label="Adresse de départ"/> */}
                <TextField source="to_address" label="Adresse de livraison" />
                {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
                {/* <FullNameDriverField label="Livreur"/> */}

                <TextField source="note" label="Commentaire pour le livreur" />
                {/* <OrderStatusField source="status" label = "État"/> */}
                {/* <br/> */}
                <Divider />
                <br />
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>Produits</Typography>
                <ReferenceManyField reference="product" target="order_id" addLabel={false}>
                    <Datagrid rowClick="">
                        <TextField source="name" label="Titre du produit" />
                        <TextField source="quantity" label="Quantité" />
                        <TextField source="total" label="Prix TTC (€)" />
                    </Datagrid>
                </ReferenceManyField>

                <br />
                {/* <Divider/> */}
                {/* <br/> */}
                {/* <TextField source="original_total" label="Montant sans frais d'attente(€)"/> */}
                {/* <TextField source="wait_amount" label="Frais d'attente(€)" className={classes.inlineBlock}/> */}
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>Montants</Typography>

                <TextField source="product_amount" label="Produits(€)" className={classes.inlineBlock} />
                <TextField source="ship_amount" label="Frais de livraison(€)" className={classes.inlineBlock} />

                <TextField source="total" label="Total(€)" className={classes.inlineBlock} />
                <PaymentStatusField paymentStatusField="payment_status" label="Etat du paiement" />
                <br />
                <ReferenceField label="Annulée par" source="canceled_by" perPage={99999999}
                    reference="driver" link="">
                    <TextField source="email" />
                </ReferenceField>
                {/* <TextField source="mode" label="Mode" /> */}
                <br />
                <RemoveButton content="Voulez-vous retirer cette commande ?" />
                {/* <TextField source="tips_amount" label="Pourboire au chauffeur(€)"/> */}
                {/* <TextField source="pt_name" label="Mode de paiement"/> */}

                {/* <br/> */}
                {/* <Divider/> */}
                {/* <br/> */}
                {/* <Typography variant="body2" color="primary">Déroulement</Typography> */}
                {/* <Typography style={{ fontWeight: 600, fontSize:15 }}>Déroulement</Typography>
                <div/> */}
                {/* <div/>
                <TextField source="wait_min" label="Temps d'attente(mn)"/> */}
                {/* <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/> */}
                {/* <div/>
                <FormattedDateField source="accept_time" format="format_date_time" label="Acceptée à" className={classes.inlineBlock}/>
                <FormattedDateField source="start_time" format="format_date_time" label="Traitée à" className={classes.inlineBlock}/> */}
            </SimpleShowLayout>
        </Show>
    )
};


export default ShowOrderUnassigned;