import React from 'react';
import { Create, TextInput, TextField, SimpleForm, required } from 'react-admin';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { CardActions, ShowButton, SaveButton, Toolbar, userLogout } from 'react-admin';

const ChangePasswordActions = ({ basePath, data, resource }) => (
    <CardActions>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
);

const ChangePasswordToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="ENREGISTRER"
            redirect="/login"
            submitOnEnter={true}
        />
        
    </Toolbar>
);

const userId = localStorage.getItem('user_id');
const role = localStorage.getItem('role');

// const EditProfile = ({ staticContext, ...props }) => {
    const ChangePassword = props => {
    return (
        
        <Create
            actions = {<ChangePasswordActions/>}
            /*
                As we are not coming for a route generated by a Resource component,
                we have to provide the id ourselves.
                As there is only one config for the current user, we decided to
                hardcode it here
            */
           
            /*
                For the same reason, we need to provide the resource and basePath props
                which are required by the Edit component
            */
            resource="password"
            basePath="/change-password"
            // redirect="false"
            title="Changer le mot de passe"
            redirect = {userLogout}
            {...props}
        >
            
            <SimpleForm toolbar={<ChangePasswordToolbar />} >
                <TextInput source="password" label="Mot de passe actuel" />
                <TextInput source="new_password" label="Nouveau mot de passe" />
                <TextInput source="confirm_new_password" label="Confirmer le nouveau mot de passe" style={{'width':'300px'}}/>
                
            </SimpleForm>
        </Create>
    );
};

// export default ChangePassword;
export default connect(undefined, { userLogout })(ChangePassword);

