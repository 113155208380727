
import React, {Fragment} from 'react';
import { ListButton, Filter, List, Responsive, ReferenceField, ReferenceManyField, Datagrid, ReferenceInput, AutocompleteInput, EditButton, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TopToolbar } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {Status} from '../../util/constant'
import {ValidateField, FormattedDateField, PhotoField} from '../../control/field/index'
import {DataLockButton, BatchDeleteButton, DataUnlockButton} from '../../control/button/index'
import { Form, Field } from 'react-final-form'
// import { removeHTML } from '../../util/function';
import Typography from '@material-ui/core/Typography';
// import QRCode from 'qrcode.react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040',},
    
    colorPrimary: {color:'#c30040',},
    
    noColorLabel: {paddingLeft: 50,  
    },

    colorValue: {color: '#c30040',paddingLeft:5},

    supplementText: {color: '#c30040',},

    noHeader:{display:'none',
    // height:'1px'
    },

    image:{width:'200px',height:'200px', borderRadius: '50%'},

    
});

const styles = {
    flex: { display: 'flex'},
    images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    // <CardActions style={cardActionStyle}>
    //     <EditButton basePath={basePath} record={data} />
    // </CardActions>
    <TopToolbar>
        <ListButton basePath={basePath}/>
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.UNLOCKED &&
            <DataLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.LOCKED &&
            <DataUnlockButton basePath={basePath} record={data} resource={resource}/>
        }
        <EditButton basePath={basePath} record={data} />    
    </TopToolbar>
);

// const DownloadQR = ({record}) => {
//     const canvas = document.getElementById("qrcode");
//     const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
//     console.log('pngUrl', pngUrl);
//     let downloadLink = document.createElement('a');
//     downloadLink.href = pngUrl;
//     downloadLink.download = 'qr_vehicule.png';
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);
//     return false;
//   };

// const CodeQR = ({record}) => {
// return <QRCode
//     id="qrcode"
//     value={"http://3.72.31.204:3000/#/vehicle/" + record.id + "/show"}
//     size={220}
//     level={'H'}
//     includeMargin={false}
//   />;
// };

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.show')}: {record ? `${record.plate_number}` : ''}</span>;
    
};

// const EventCreate = ({ record }) => {
//     return <EventCreateButton record={record} redirect="show"/>;
    
// };

// const EventEdit = ({ record }) => {
//     return <EventEditButton record={record}/>;
    
// };


// const HtmlTextField = ({ source, record = {}, label}) => {
//     return <span class="MuiTypography-root MuiTypography-body2">{removeHTML(record[source])}</span>;
// }
    
// HtmlTextField.defaultProps = {
//     addLabel: true,
// };

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} content="Voulez-vous supprimer ces événements ?" />
    </Fragment>
);

const EventPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    
    
    return(
        <div style={styles.flexColumn}>
        {/* <span>Kilométrage au départ : {record.start_km}</span><br/>
        <span>Kilométrage au retour : {record.end_km}</span><br/>
        <span>Révision nécessaire : {record.revision === "1" ? "Oui" : "Non"}</span><br/> */}
        {/* <span>Commentaire : {removeHTML(record.comment)}</span><br/> */}
        </div>
    )
};

const EventFilter = (props) => (
    <Filter {...props}>
        {/* <ReferenceInput
            label="Nom de Jockey"
            source="user_code"
            reference="driver"
            // sort={{ field: 'user_code', order: 'ASC' }}
            // filterToQuery={searchText => ({ user_code: searchText })}
            allowEmpty={true}
            alwaysOn
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput> */}
        <ReferenceInput label="Nom de Jockey" reference="driver" source="driver_id"   alwaysOn
                      >
                            <AutocompleteInput optionText="user_code" emptyText="Tous"/>
                        </ReferenceInput>
    </Filter>
);

const ListEvent = props  => (
<List title=" " {...props} filter={{vehicle_id: props.id}} filters={<EventFilter />} exporter={false}  bulkActionButtons={<BulkActionButtons />}>
                    <Datagrid  expandHeader={<div/>}>
                        <FormattedDateField source="event_date" label="Date"  locales="fr-FR"/>
                        {/* <TextField source="user_code" label = "Nom du Jockey assigné"/> */}
                        <ReferenceField label="Nom du Jockey assigné" source="driver_id" reference="driver" link="show">
                            <TextField source="user_code" />
                        </ReferenceField>
                        <FormattedDateField source="start_time" label = "Heure de départ" format="HH:mm"/>
                        <FormattedDateField source="end_time" label = "Heure de retour"  format="HH:mm"/>
                        <TextField source="start_km" label = "Kilométrage au départ"/>
                        <TextField source="end_km" label = "Kilométrage au retour"/>
                        <ValidateField source="revision" label="Révision nécessaire"/>
                        {/* <HtmlTextField source="comment" label = "Commentaire"/>
                        <EventEdit/> */}
                    </Datagrid>
                    </List>
);

const ListEventSmall = props  => (
    <List title=" "  {...props} filter={{vehicle_id: props.id}} filters={<EventFilter />} exporter={false}  bulkActionButtons={<BulkActionButtons />}>
                        <Datagrid expand={<EventPanel/>} expandHeader={<div/>}>
                        <FormattedDateField source="event_date" label="Date"  locales="fr-FR"/>
                        {/* <TextField source="user_code" label = "Nom du Jockey assigné"/> */}
                        <ReferenceField label="Nom du Jockey assigné" source="driver_id" reference="driver" link="show">
                            <TextField source="user_code" />
                        </ReferenceField>
                        <FormattedDateField source="start_time" label = "Heure de départ" format="HH:mm"/>
                        <FormattedDateField source="end_time" label = "Heure de retour"  format="HH:mm"/>
                        {/* <EventEdit/> */}
                        
                    </Datagrid>
                        </List>
    );

const ShowVehicle = ({staticContext, ...props}) => (
    
        <Show title={<Title/>}  {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
        {/* <Typography style={{ fontWeight: 600, fontSize:15 }}>INFOS DE BASE</Typography> */}
        {/* <br/> */}
            <TextField source="plate_number" label = "Immatriculation"/>
            <FormattedDateField source="circulation_date" label="Date de première mis en circulation"  locales="fr-FR"/>
            <PhotoField source="front_image_url" label="Photo face avant du véhicule" width='200' height='auto' round='0%'/>
            <PhotoField source="rear_image_url" label="Photo face arrière du véhicule" width='200' height='auto' round='0%'/>
            <PhotoField source="gray_card_recto_url" label="Carte grise vecto" width='200' height='auto' round='0%'/>
            <PhotoField source="gray_card_verso_url" label="Carte grise verso" width='200' height='auto' round='0%'/>

            {/* <TextField source="distance" label = "Distance parcourue (km)"/>
            <TextField source="duration" label = "Durée parcourue (mn)"/> */}
            {/* <ValidateField source="is_locked" label="Verrouillée"/> */}
            {/* <CodeQR/>
            <span onClick={DownloadQR} style={{"color": "blue", "cursor": "pointer"}}>Télécharger le Code QR</span> */}
            <br/>
            {/* <Form  onSubmit={onSubmit}>
            <ReferenceInput label="Chauffeurs" reference="driver" source="driver_id"
                      >
                            <AutocompleteInput optionText="user_code"/>
                        </ReferenceInput>
            </Form> */}
            
            {/* <Divider/>
            <br/>
            <Typography style={{ fontWeight: 600, fontSize:15 }}>ÉVÉNEMENTS</Typography>
            <br/>
            {/* <EventCreate/> */}
            
        </SimpleShowLayout>
    </Show>
    
);


export default ShowVehicle;