
import React, {Fragment} from 'react';
import { TextInput, Labeled, FormDataConsumer, ReferenceInput, FileInput, AutocompleteInput, SelectInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';
import { withStyles } from '@material-ui/core/styles';
import { DateInput } from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

import {activityTypes, deliveryTypes, vehicleColors, ACTIVITY_TYPE_TOURISM, ACTIVITY_TYPE_DELIVERY, ACTIVITY_TYPE_UTILITY} from '../../util/constant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);
const DriverNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.edit')}: {record ? `${record.plate_number}` : ''}</span>;
};

const EditVehicle = ({classes, permissions, ...props })  => {
    const translate = useTranslate();
    return (
        <Edit title={<Title />} {...props} actions={<EditActions/>}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                
        {permissions === 'Admin' &&
            <SelectInput label="Type de service" source="activity_type" choices={activityTypes} />}
            {permissions === 'Delivery' &&
            <SelectInput label="Type de service" source="activity_type" choices={deliveryTypes} />}
            
            <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    (formData.activity_type === ACTIVITY_TYPE_TOURISM ||
                        formData.activity_type === ACTIVITY_TYPE_DELIVERY ||
                        formData.activity_type === ACTIVITY_TYPE_UTILITY 
                    ) &&
                    <Fragment>
                    <ReferenceInput  {...rest}
                //    onChange={value => dispatch(change(REDUX_FORM_NAME, 'model_id', null))}
                   label="Marque" source="brand_id" perPage={99999}
             sort={{field: 'name', order: 'ASC'}}
             
            reference="vehicle_brand" validate={required()} > 
                <AutocompleteInput optionText="name"
                    
             
                />       
            </ReferenceInput>

                    <ReferenceInput label="Modèle" source="model_id" perPage={99999}
             sort={{field: 'name', order: 'ASC'}} filter={{brand_id: formData.brand_id}}
            reference="vehicle_model" validate={required()}  {...rest}> 
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput>
            <TextInput source="plate_number" label = "Immatriculation"/>
            {/* label="Date de 1e mise en circulation" */}
            
            <MuiPickersUtilsProvider utils={MomentUtils}  style={{'width':'800px'}}>
            
            <DateInput source="circulation_date"  label="&nbsp;&nbsp;Date"
            style={{width:'800'}}
            autoComplete="" options={{ format: '  DD/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
            <Labeled label="(de première mise en circulation)">
            </Labeled>
            </MuiPickersUtilsProvider>
                    </Fragment>
                    
                 }
             </FormDataConsumer>    

                   
            
             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    formData.activity_type === ACTIVITY_TYPE_DELIVERY &&
                    <Fragment>
                    <NumberInput label="Poids maximal supporté(kg)"  source="weight" validate={required()} style={{'width':'300px'}}/>
                    <div/>
                    <NumberInput label="Taille du véhicule(m3)"  source="volume" validate={required()}/>
                     <FileInput source="front_image" label="Photo du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    </Fragment>
                    
                 }
             </FormDataConsumer>

             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    formData.activity_type === ACTIVITY_TYPE_TOURISM &&
                    <Fragment>
                    <NumberInput label="Nombre de passagers max."  source="seats" validate={required()}/>
                    <div/>
                    <SelectInput label="Couleur" source="color" choices={vehicleColors} validate={required()} style={{'width':'300px'}}/>
                    <FileInput source="front_image" label="Photo face avant du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    <FileInput source="rear_image" label="Photo face arrière du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    </Fragment>
                    
                 }
             </FormDataConsumer>

             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    formData.activity_type === ACTIVITY_TYPE_UTILITY &&
                    <Fragment>
                     <FileInput source="front_image" label="Photo du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    </Fragment>
                    
                 }
             </FormDataConsumer>

             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    (formData.activity_type === ACTIVITY_TYPE_TOURISM ||
                        formData.activity_type === ACTIVITY_TYPE_DELIVERY ||
                        formData.activity_type === ACTIVITY_TYPE_UTILITY 
                    ) &&
                    <Fragment>
                    <FileInput source="gray_card_recto" label="Carte grise recto" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="gray_card_verso" label="Carte grise verso" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>    
            <div>
                                    <span>Choisissez un chauffeur à assigner</span>
                                    {/* <TextInput multiline source="reason" fullWidth={true} validate={required()}/> */}
                                    <ReferenceInput label="Chauffeur" source="driver_id" reference="driver" perPage={9999999}>
                                        <AutocompleteInput optionText={DriverNameField} />
                                    </ReferenceInput>
                                </div>
                    </Fragment>
                    
                 }
             </FormDataConsumer>    

        </SimpleForm>
    </Edit>
)};

export default EditVehicle;