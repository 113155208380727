
import React, {useState} from 'react';
import { Labeled, NumberInput, FormDataConsumer, ArrayInput, SimpleFormIterator, CheckboxGroupInput, Pagination, TextField, ReferenceManyField, Datagrid, FormTab, TabbedForm, ReferenceInput, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { Field } from 'react-final-form';
import { EditButton, ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { TimeInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {orderTypes, OrderType} from '../../util/constant'
import { ValidateField } from '../../control/field';
import {markupTypes, unitTypes} from '../../util/constant'
import {activityTypes} from '../../util/constant'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const Title = ({ record }) => {
    var string = "";
    if(record){
        const vehicleTypeName = record ? record.vehicle_type_name : '';
        const orderType = record ? record.order_type : 0;
        if(orderType == OrderType.IMMEDIATE)
            string = vehicleTypeName + " - Immédiate";
        else
            string = vehicleTypeName + " - Réservation à l'avance";
    }
    const translate = useTranslate();
    // return <span>{translate('resources.price_time.edit')} pour: {record ? `${string}` : ''}</span>;
    return <span>{translate('resources.price_time.edit')}</span>;
};

// {({ formData, ...rest }) => 
//                     (typeof(formData.every_day) === 'undefined' ||
//                     formData.every_day === 'undefined' ||
//                     formData.every_day.length === 0 ||
//                     formData.every_day[0] === '0' || 
//                     formData.every_day[0] !== 1) 
//                      &&
//                     <CheckboxGroupInput source="days" label="" choices={[
//                         { id: 1, name: 'Lundi' },
//                         { id: 2, name: 'Mardi' },
//                         { id: 3, name: 'Mercredi' },
//                         { id: 4, name: 'Jeudi' },
//                         { id: 5, name: 'Vendredi' },
//                         { id: 6, name: 'Samedi' },
//                         { id: 7, name: 'Dimanche' },
//                     ]} />
//                  }
const DaysField = ({ record }) => {
    console.log('Edit time price record: ' + JSON.stringify(record));
    const [checkEveryDay, setCheckEveryDay] = useState(record.every_day[0] === '1');
    
    // props.record.every_day[0] === '1';
    
    const onChangeEveryDay = (e) => {
        // alert(e.target.checked);
        // alert('xxx');
        setCheckEveryDay(!checkEveryDay);
    }

    return <div><CheckboxGroupInput source="every_day" label="" choices={[
        { id: '1', name: 'Tous les jours' },
    ]} onChange={(e) => onChangeEveryDay(e)}/>

        <FormDataConsumer subscription={{ values: true }}>

        {({ formData, ...rest }) => 
            !checkEveryDay &&
            <CheckboxGroupInput source="days" label="" choices={[
                { id: 1, name: 'Lundi' },
                { id: 2, name: 'Mardi' },
                { id: 3, name: 'Mercredi' },
                { id: 4, name: 'Jeudi' },
                { id: 5, name: 'Vendredi' },
                { id: 6, name: 'Samedi' },
                { id: 7, name: 'Dimanche' },
            ]} />
        }
        </FormDataConsumer>
    </div>
}

const EditPriceTime = ({permissions, ...props })  => {
    const classes = useStyles();
    // var everyDayChecked = false;

    
    return (
    <Edit title={<Title />} {...props} actions={<EditActions/>} {...props}>
     <SimpleForm redirect="list">
     <SelectInput source="activity_type" label="Type de service" validate={required()} 
                choices={activityTypes} className={classes.inlineBlock}/>
         <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
            reference="vehicle_type" label="Gamme de véhicule">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={activityTypes} className={classes.inlineBlock}/> */}
                            

            <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/>
            
            <DaysField/>
                {/* <NumberInput source="from_time" label="Heure de début"/> */}
                <MuiPickersUtilsProvider utils={MomentUtils}>

                <TimeInput source="from_time" label="Heure de début"
                    options={{ format: 'HH:mm' }} providerOptions={{ utils: DateFnsUtils, locale: frLocale }}/>


                <TimeInput source="to_time" label="Heure de fin"
                    options={{ format: 'HH:mm' }} providerOptions={{ utils: DateFnsUtils, locale: frLocale }}/>

            </MuiPickersUtilsProvider>

          
                {/* <SelectInput source="type" label="Type" validate={required()} choices={markupTypes} defaultValue="0"/> */}
                <NumberInput source="markup" label="Valeur"/>
                <SelectInput source="markup_unit" label="Unité" validate={required()} choices={unitTypes} defaultValue="0"/>
                {/* <ArrayInput source="items">
                <SimpleFormIterator>
                </SimpleFormIterator>
            </ArrayInput> */}
        </SimpleForm>
    </Edit>
)};

export default EditPriceTime;