
import React, {useState} from 'react';
import {ArrayInput, FormDataConsumer, NumberInput, CheckboxGroupInput, SimpleFormIterator, ReferenceInput, TextInput, Create, SimpleForm, SelectInput, required, useTranslate} from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from '@date-io/date-fns';
import { TimeInput } from 'react-admin-date-inputs2';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ListButton} from 'react-admin';
// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {orderTypes, markupTypes, unitTypes} from '../../util/constant'
import {activityTypes} from '../../util/constant'
// import { formValueSelector } from 'redux-form';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price_time.create')}</span>;
};

const DaysField = ({ record }) => {
    // console.log('Edit time price record: ' + JSON.stringify(record));
    const [checkEveryDay, setCheckEveryDay] = useState(false);
    
    // props.record.every_day[0] === '1';
    
    const onChangeEveryDay = (e) => {
        // alert(e.target.checked);
        // alert('xxx');
        setCheckEveryDay(!checkEveryDay);
    }

    return <div><CheckboxGroupInput source="every_day" label="" choices={[
        { id: '1', name: 'Tous les jours' },
    ]} onChange={(e) => onChangeEveryDay(e)}/>

        <FormDataConsumer subscription={{ values: true }}>

        {({ formData, ...rest }) => 
            !checkEveryDay &&
            <CheckboxGroupInput source="days" label="" choices={[
                { id: 1, name: 'Lundi' },
                { id: 2, name: 'Mardi' },
                { id: 3, name: 'Mercredi' },
                { id: 4, name: 'Jeudi' },
                { id: 5, name: 'Vendredi' },
                { id: 6, name: 'Samedi' },
                { id: 7, name: 'Dimanche' },
            ]} />
        }
        </FormDataConsumer>
    </div>
}

const tzOffset = new Date().getTimezoneOffset() / 60
const dateParser = v => {
  const regexp = /(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2}):(\d{2})/
  let match = regexp.exec(v)
  if (match === null) return "No match"

  let year = match[1]
  let month = match[2]
  let day = match[3]
  let hour = match[5]
  let min = match[6]

  if (tzOffset < 0) {
    const date = new Date(v)
    match = regexp.exec(date.toISOString())
    if (!match) return null
    year = match[1]
    month = match[2]
    day = match[3]
    hour = match[4]
    min = match[5]
  }
  const date = [year, month, day].join('-')
  const time = [hour, min].join(':')
  const result = date + 'T' + time + 'Z'
  return result
}

const CreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const CreatePriceTime = props => {
    const classes = useStyles();
    return (
    
    <Create title={<Title/>} {...props} actions={<CreateActions/>}>
        <SimpleForm redirect="list">
        <SelectInput source="activity_type" label="Type de service" validate={required()} 
                choices={activityTypes} className={classes.inlineBlock}/>
        <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
            reference="vehicle_type" label="Gamme de véhicule">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={activityTypes} className={classes.inlineBlock}/>
                             */}
            <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/>
            {/* <ArrayInput source="configuration">
                <SimpleFormIterator> */}
                {/* <CheckboxGroupInput source="days" choices={[
                        { id: 0, name: 'Tous les jours' },
                        { id: 1, name: 'Lundi' },
                        { id: 2, name: 'Mardi' },
                        { id: 3, name: 'Mercredi' },
                        { id: 4, name: 'Jeudi' },
                        { id: 5, name: 'Vendredi' },
                        { id: 6, name: 'Samedi' },
                        { id: 7, name: 'Dimanche' },
                    ]} /> */}
                    <DaysField/>

                    <MuiPickersUtilsProvider utils={MomentUtils}>

<TimeInput source="from_time" label="Heure de début" 
    options={{ format: 'HH:mm' }} />


<TimeInput source="to_time" label="Heure de fin" 
    options={{ format: 'HH:mm' }} />

</MuiPickersUtilsProvider>
                {/* <SelectInput source="type" label="Type" validate={required()} choices={markupTypes} defaultValue="0"/> */}
                <NumberInput source="markup" label="Valeur"/>
                <SelectInput source="markup_unit" label="Unité" validate={required()} choices={unitTypes} defaultValue="0"/>
                {/* </SimpleFormIterator> */}
            {/* </ArrayInput> */}
            
        </SimpleForm>
    </Create>
)};

export default CreatePriceTime;

{/* options={{ format: 'dd/MM/yyyy' }  */}