
import React, {Link} from 'react';
import { ListButton, EditButton, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TopToolbar } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {Status} from '../../util/constant'
import {ValidateField} from '../../control/field/index'
import {DataLockButton, DataUnlockButton} from '../../control/button/index'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }


const ShowActions = ({ basePath, data, resource }) => (
    // <CardActions style={cardActionStyle}>
    //     <EditButton basePath={basePath} record={data} />
    // </CardActions>
    <TopToolbar>
        <ListButton basePath={basePath}/>
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.UNLOCKED &&
            <DataLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.LOCKED &&
            <DataUnlockButton basePath={basePath} record={data} resource={resource}/>
        }
        <EditButton basePath={basePath} record={data} />
        
    </TopToolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_type.show')}: {record ? `${record.name}` : ''}</span>;
    
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowVehicleType = ({staticContext, ...props}) => (
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            <TextField source="name" label = "Nom"/>
            <TextField source="description" label = "Description"/>
            {/* <TextField source="max_seats" label = "Sièges max"/> */}
            {/* <TextField source="max_luggages" label = "Bagagges max"/> */}
            {/* <TextField source="ordering" label = "Ordre d'apparition"/> */}
            {/* <ImageField source="map_icon.url" label="Icone sur la carte"/> */}
            <ImageField source="select_icon.url" label="Icone pour la sélection"/>
            {/* <LockField source="is_locked" label="État"/> */}
            <ValidateField source="is_locked" label='Verrouillé'/>
        </SimpleShowLayout>
    </Show>
);


export default ShowVehicleType;