import React, {Fragment} from 'react';
import { ReferenceField, ReferenceInput, List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {activityTypes, orderTypes} from '../../util/constant'
import {OrderTypeField, OrderTypeFilter, OrderActivityTypeField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} content="Voulez-vous supprimer ces prix ?" />
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price.list')}</span>;
};

const ActivityTypeTextField = ({ source, record = {}, label}) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if(activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    return <span/>;
    
}

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        
        <SelectInput source="activity_type" label="Type de service" alwaysOn
                choices={activityTypes} />
        <ReferenceInput source="vehicle_type_id" perPage={99999}  alwaysOn
            reference="vehicle_type" label="Game de véhicule">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={orderActivityTypes} className={classes.inlineBlock}/> */}
            <SelectInput source="order_type" label="Type de commande"  alwaysOn
                choices={orderTypes} />
            <div/>
            
        
    </Filter>
);

const ListPrice = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            {/* <TextField source="vehicle_type_name" label = "Type de véhicule"/> */}
            {/* <OrderActivityTypeField label="Type d'activité"/> */}
            <ActivityTypeTextField label="Type de service" source="activity_type"/>
            <OrderTypeField source="order_type" label="Type de commande"/>
            <ReferenceField label="Gamme de véhicule" source="vehicle_type_id" reference="vehicle_type"
            linkType={false} >
                <TextField source="name" />
            </ReferenceField>

            {/* <TextField source="vehicle_type_name" label = "Type de véhicule"/> */}
            {/* <TextField source="vehicle_sub_type_name" label = "Gamme"/> */}

            <TextField source="min" label="Minimum($)"/>
            <TextField source="base" label = "Base($)"/>
            <TextField source="per_km" label="$/km"/>
            <TextField source="per_minute" label="$/minute"/>
            {/* <TextField source="wait" label="Attente(€/minute)"/>
            <TextField source="markup" label="Supplément(€)"/> */}
            {/* <TextField source="quantity_markup" label="Supplément au dela 3 passagers(€/passager)"/> */}
        </Datagrid>
    </List>
);

export default ListPrice;

