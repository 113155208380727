import React from 'react';
import Typography from '@material-ui/core/Typography';
import * as Constant from '../../util/constant';

export const OrderTypeFilterField = ({ record }) => {
    return <span>{record.id == Constant.OrderType.IMMEDIATE ? 'Immédiate' : "Réservation à l'avance"}</span>;
};

const OrderTypeField = ({ record = {}, label}) => {
    return <div>
         <Typography variant="body2">
        {record.order_type == Constant.OrderType.IMMEDIATE? 'Immédiate' : "Réservation à l'avance"}
        </Typography>
    </div>;
}

OrderTypeField.defaultProps = {
    addLabel: true,
};

export default OrderTypeField;
