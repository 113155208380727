import React, { Fragment } from 'react';
import { ReferenceInput, AutocompleteInput, ReferenceField, Filter, TextInput, SelectInput, List, Datagrid, TextField, DateField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {
    OrderTypeField, OrderTypeFilterField, OrderStatusField, OrderRunningStatusFilterField, FormattedDateField, OrderActivityTypeField,
    FullNameClientField, FullNameDriverField, TimeSlotTextField,
    FullNameClientWithTypeField,
    DeliveryAddressField
} from '../../control/field/index'
import { orderRunningStatusValues } from '../../control/field/order-status-field'
import { activityTypes } from '../../util/constant';
const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

const Title = ({ record }) => {
    return <span>Commandes</span>;
};

const ActivityTypeTextField = ({ source, record = {}, label }) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if (activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    return <span />;

}

const ProductField = ({ record = {} }) => {
    const options = JSON.parse(record.options_new);

    return (
        <div >
            <div>
                {options.map((option, index) => {
                    if (index === options.length - 1)
                        return <span>{option.name} : {option.quantity}</span>
                    return <span>{option.name} : {option.quantity},<br /></span>
                })}
            </div>
            {/* <ValidityDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>

    )
}


ProductField.defaultProps = {
    addLabel: true,
};

// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Nº de commande" source="order_number" alwaysOn resettable />
        {/* {permissions === 'Admin' &&<SelectInput label="Type de service" source="activity_type" alwaysOn choices={activityTypes}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'
        />} */}
        {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <SelectInput optionText="name"
                
            />
            </ReferenceInput> */}
        {/* <ReferenceInput label="Email client" source="client_id" reference="client" perPage={99999} alwaysOn resettable
            emptyText="Tous"
        >
            <AutocompleteInput optionText="email"
            />
        </ReferenceInput> */}
        <TextInput label="Nom du client" source="to_head_name" alwaysOn resettable />
        <ReferenceInput label="Email livreur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable>
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput>
        <SelectInput label="État" source="status" choices={orderRunningStatusValues}
            optionText={<OrderRunningStatusFilterField />}
            alwaysOn />

        {/* <TextInput label="Adresse de départ" source="from_address" resettable/> */}
        <TextInput label="Adresse de livraison" source="to_address" alwaysOn resettable />
        <TextInput label="Code postal de livraison" source="to_postal_code" alwaysOn resettable />
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);


const ListOrderRunning = (props) => (
    <List title={<Title />} {...props}
        filters={<ListFilter />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="order_number" label="Nº de commande" />
            <FormattedDateField source="pick_time" format="format_date" label="Date de livraison" />



            {/* <ActivityTypeTextField label="Type de service"/> */}


            <TimeSlotTextField label="Créneau de livraison" />
            {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" 
            reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField> */}
            {/* <OrderActivityTypeField source="order_type" label= "Type d'activité"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            {/* <FullNameClientField label="Client" /> */}
            <FullNameClientWithTypeField label="Client" />
            {/* <TextField source="from_address" label="Adresse de départ"/> */}
            {/* <TextField source="to_address" label="Adresse de livraison" /> */}
            <DeliveryAddressField label="Adresse de livraison" />
            <ProductField label="Produit" />
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            {/* <FullNameDriverField label="Livreur" /> */}
            <TextField source="total" label="Prix(€)" />
            <OrderStatusField source="status" label="État" />
            <OrderTypeField source="order_type" label="Type de commande" />
            <FormattedDateField source="create_time" format="format_date_time" label="Date de commande" />
        </Datagrid>
    </List>
);

export default ListOrderRunning;

