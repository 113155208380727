
import React, { Fragment } from 'react';
import { REDUX_FORM_NAME, FileInput, ImageField, TextInput, Labeled, NumberInput, FormDataConsumer, ReferenceInput, AutocompleteInput, RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, useTranslate} 
from 'react-admin';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { DateInput } from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {activityTypes, deliveryTypes, ACTIVITY_TYPE_DELIVERY, ACTIVITY_TYPE_TOURISM, ACTIVITY_TYPE_UTILITY} from '../../util/constant'
import {vehicleColors} from '../../util/constant';

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
const DriverNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';
// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.create')}</span>;
};

const CreateVehicle = ({permissions, ...props}) => {
    const translate = useTranslate();
    
    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            {permissions === 'Admin' &&
            <SelectInput label="Type de service" source="activity_type" choices={activityTypes} />}
            {permissions === 'Delivery' &&
            <SelectInput label="Type de service" source="activity_type" choices={deliveryTypes} />}
            <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    (formData.activity_type === ACTIVITY_TYPE_TOURISM ||
                        formData.activity_type === ACTIVITY_TYPE_DELIVERY ||
                        formData.activity_type === ACTIVITY_TYPE_UTILITY 
                    ) &&
                    <Fragment>
                    <ReferenceInput  {...rest}
                //    onChange={value => dispatch(change(REDUX_FORM_NAME, 'model_id', null))}
                   label="Marque" source="brand_id" perPage={99999}
             sort={{field: 'name', order: 'ASC'}}
             
            reference="vehicle_brand" validate={required()} > 
                <AutocompleteInput optionText="name"
                    
             
                />       
            </ReferenceInput>

                    <ReferenceInput label="Modèle" source="model_id" perPage={99999}
             sort={{field: 'name', order: 'ASC'}} filter={{brand_id: formData.brand_id}}
            reference="vehicle_model" validate={required()}  {...rest}> 
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput>
            <TextInput source="plate_number" label = "Immatriculation"/>
            {/* label="Date de 1e mise en circulation" */}
            
            <MuiPickersUtilsProvider utils={MomentUtils}  style={{'width':'800px'}}>
            
            <DateInput source="circulation_date"  label="&nbsp;&nbsp;Date"
            style={{width:'800'}}
            autoComplete="" options={{ format: '  DD/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
            <Labeled label="(de première mise en circulation)">
            </Labeled>
            </MuiPickersUtilsProvider>
                    </Fragment>
                    
                 }
             </FormDataConsumer>    

                   
            
             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    formData.activity_type === ACTIVITY_TYPE_DELIVERY &&
                    <Fragment>
                    <NumberInput label="Poids maximal supporté(kg)"  source="weight" validate={required()} style={{'width':'300px'}}/>
                    <div/>
                    <NumberInput label="Taille du véhicule(m3)"  source="volume" validate={required()}/>
                     <FileInput source="front_image" label="Photo du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    </Fragment>
                    
                 }
             </FormDataConsumer>

             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    formData.activity_type === ACTIVITY_TYPE_TOURISM &&
                    <Fragment>
                    <NumberInput label="Nombre de passagers max."  source="seats" validate={required()} style={{'width':'300px'}}/>
                    <div/>
                    <SelectInput label="Couleur" source="color" choices={vehicleColors} validate={required()}/>
                    <FileInput source="front_image" label="Photo face avant du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    <FileInput source="rear_image" label="Photo face arrière du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    </Fragment>
                    
                 }
             </FormDataConsumer>

             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    formData.activity_type === ACTIVITY_TYPE_UTILITY &&
                    <Fragment>
                     <FileInput source="front_image" label="Photo du véhicule" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
                    </FileInput>      
                    </Fragment>
                    
                 }
             </FormDataConsumer>

             <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => 
                    (formData.activity_type === ACTIVITY_TYPE_TOURISM ||
                        formData.activity_type === ACTIVITY_TYPE_DELIVERY ||
                        formData.activity_type === ACTIVITY_TYPE_UTILITY 
                    ) &&
                    <Fragment>
                    <FileInput source="gray_card_recto" label="Carte grise recto" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="gray_card_verso" label="Carte grise verso" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>    
            <div>
                                    <span>Choisissez un chauffeur à assigner</span>
                                    {/* <TextInput multiline source="reason" fullWidth={true} validate={required()}/> */}
                                    <ReferenceInput label="Chauffeur" source="driver_id" reference="driver" perPage={9999999} key="id" {...rest} >
                                        <AutocompleteInput optionText={DriverNameField} optionValue="id"/>
                                    </ReferenceInput>
                                </div>
                    </Fragment>
                    
                 }
             </FormDataConsumer>    
            

            {/* <RadioButtonGroupInput source="is_locked" label="Verrouillé?" 
            choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue='0'/> */}
        </SimpleForm>
    </Create>
)};

const mapStateToProps = state => ({
    // isSubmitting: isSubmitting()(state)
});


const mapDispatchToProps = {
    change,
    
};

export default connect(mapStateToProps, mapDispatchToProps)(
    CreateVehicle
);

// export default CreateVehicle;
