
import React, { Link } from 'react';
import {
    Labeled, Pagination, ReferenceField, SimpleShowLayout, TextField, Show, useTranslate, ImageField, TabbedShowLayout, Tab, UrlField,
    ReferenceManyField, Datagrid
} from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { DeliveryFeeButton, DeleteButton, CustomerLockButton, CustomerUnlockButton, WalletEditButton, CustomerPayInstantButton } from '../../control/button/index';
import ImageViewer from '../../control/viewer/image-viewer';
import {
    OrderStatusField, FormattedDateField, AvatarField, TimeSlotTextField, FullNameDriverField,
    GenderField, GenderFilterField, LockField, ValidateField, DocNameField, FileCountField, DriverDataField, ValidityDateField,
    FullNameClientWithTypeField
} from '../../control/field/index'
import { Status, FALSE, TRUE, ANIMAL_TYPE_FREE, ANIMAL_TYPE_CAGE, USER_TYPE_CLIENT_PRO, activityTypes } from '../../util/constant'
// import {
//     Icon_Visa,
//     Icon_MasterCard
//   } from 'material-ui-credit-card-icons';

import { BACKEND_FILE_URL } from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';
import { bool } from 'prop-types';
import CustomerPaylateButton from '../../control/button/customer-paylate-button';
import DownloadButton from '../../control/button/download-button';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', }

});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};


const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};




const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        <ListButton basePath={basePath} />
        {/* <BackButton/> */}
        {/* <EditButton basePath={basePath} record={data} /> */}
        {typeof data != 'undefined' && typeof data.pay_late != 'undefined' && data.pay_late === "0" && <CustomerPaylateButton basePath={basePath} record={data} resource={resource} />}
        {typeof data != 'undefined' && typeof data.pay_late != 'undefined' && data.pay_late === "1" && <CustomerPayInstantButton basePath={basePath} record={data} resource={resource} />}
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource} />
        }
        {/* <EditButton basePath={basePath} record={data} /> */}
        <DeleteButton resource="client" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce client? Toutes les donnnées personnelles de ce client seront supprimées" />
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client.show')}: {record ? `${record.email}` : ''}</span>;
};

const WalletBalanceField = ({ record = { wallet: { balance: 0 } } }) => {
    const classes = useStyles();
    const value = record.wallet.balance;
    return <div>
        <Typography variant="body1" style={{ fontWeight: 600, fontSize: 15 }}>
            Montant du Wallet : {value} $
        </Typography>
        {/* <WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?"/> */}
    </div>;

}

WalletBalanceField.defaultProps = {
    addLabel: false,
};


const CardNumberField = ({ record, label }) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    console.log('Card ' + JSON.stringify(record));
    console.log('Car data ' + JSON.stringify(record.data));
    return <div><span>{'xxxx xxxx xxxx ' + record.data.last4}</span></div>
    // return <div></div>
}

const ExpiryField = ({ record, label }) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    //    console.log('Card ' + JSON.stringify(record));
    //  console.log('Car data ' + JSON.stringify(record.data));
    if (typeof (record) !== 'undefined' && typeof (record.data) !== 'undefined')
        return <div><span>{record.data.expiry_month + '/' + record.data.expiry_year}</span></div>
    return <div></div>
}

const ClientTypeField = ({ record }) => {
    return <span>{record.user_type == USER_TYPE_CLIENT_PRO ? 'Client professionnel' : 'Client particulier'}</span>;
};

ClientTypeField.defaultProps = {
    addLabel: true,
};

export const AnimalSizeField = ({ record = {}, label }) => {
    return record.animal.type === ANIMAL_TYPE_FREE ? <div>
        <Labeled label="Taille (cm)">
            <Typography variant="body2">
                {record.animal.size}
            </Typography>
        </Labeled>
    </div> : <div />;
}

AnimalSizeField.defaultProps = {
    addLabel: false,
};

export const AnimalCageSizeField = ({ record = {}, label }) => {
    var cageHeight = record.animal.cage_height;
    var cageWidth = record.animal.cage_width;
    var cageLength = record.animal.cage_length;
    var cageSize = record.animal.cage_size;
    var type = record.animal.type;
    // return <Typography variant="body2">
    //         {type} - {cageHeight} - {cageWidth} - {cageLength} - {cageSize}
    //     </Typography>;
    // var isNotNull = typeof(cageHeight) !== "undefined" && cageHeight !== "undefined" && cageHeight !== null;
    return type === ANIMAL_TYPE_CAGE ? <div>
        {/* <Labeled  label="Taille de la cage/panier" styles={{'fontWeight': 'bold'}}> */}
        <Labeled label="Hauteur de la cage/panier(cm)">
            <Typography variant="body2">
                {cageHeight}
            </Typography>
        </Labeled>
        <Labeled label="Largeur de la cage/panier(cm)">
            <Typography variant="body2">
                {cageWidth}
                {/* XXX */}
            </Typography>
        </Labeled>
        <Labeled label="Longeur de la cage/panier(cm)">
            <Typography variant="body2">
                {cageLength}
            </Typography>
        </Labeled>
        <Labeled label="Taille totale de la cage/panier(cm)">
            <Typography variant="body2">
                {cageSize}
            </Typography>
        </Labeled>
        {/* </Labeled> */}
    </div> : <div />;
}

AnimalCageSizeField.defaultProps = {
    addLabel: false,
};

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            {/* <ValidityDateField record={record}/> */}
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier:</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>

    )
};

// const CardTypeField = ({record, label}) => {
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined'){
//         if(record.data.card_type == "Visa")
//             return <Icon_Visa/>

//         if(record.data.card_type == "MasterCard")
//             return <Icon_MasterCard/>

//         return <span>{record.data.card_type}</span>
//     }
//     return <span>"Inconnu"</span>
// }

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const PhotoField = ({ record = {}, source, label }) => {
    // const classes = useStyles();
    return <ImageViewer files={[]} fileUrl={record[source]} title={label} width='60' height='60' round='50%' />

}

// const AnimalTypeField = ({record, label}) => {
//     // const classes = useStyles();
//     // const data = JSON.parse(record.data);
// //    console.log('Card ' + JSON.stringify(record));
//   //  console.log('Car data ' + JSON.stringify(record.data));
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
//         return <div><span>{record. + '/' + record.data.expiry_year}</span></div>    
//     return <div></div>
// }
const WALLET_ACTION_END_CHARGE = 1;
const WALLET_ACTION_CANCEL_CHARGE = 2;
const WALLET_ACTION_CREATE_CHARGE = 3;
const WALLET_ACTION_TIPS = 4;
const WALLET_ACTION_RESET = 5;
const WALLET_ACTION_COMMISSION = 6;
const WALLET_ACTION_REIMBURSE = 7;
const WALLET_ACTION_EDIT = 8;
const WALLET_ACTION_ADD = 9;


const WalletActionField = ({ record = { action: 0 } }) => {
    const classes = useStyles();
    let text = "";
    if (record.action == WALLET_ACTION_END_CHARGE) text = "Frais de la course";
    else
        if (record.action == WALLET_ACTION_CANCEL_CHARGE) text = "Frais d'annulation";
        else
            if (record.action == WALLET_ACTION_CREATE_CHARGE) text = "Frais de création de la course";
            else
                if (record.action == WALLET_ACTION_TIPS) text = "Pourboire";
                else
                    if (record.action == WALLET_ACTION_RESET) text = "Remise à 0";
                    else
                        if (record.action == WALLET_ACTION_REIMBURSE) text = "Remboursé";
                        else
                            if (record.action == WALLET_ACTION_EDIT) text = "Changé par l'admin";
                            else
                                if (record.action == WALLET_ACTION_ADD) text = "Ajouté par l'admin";
    return <div>
        <Typography variant="body2">
            {text}
        </Typography></div>;

}

WalletActionField.defaultProps = {
    addLabel: false,
};

const ActivityTypeTextField = ({ source, record = {}, label }) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if (activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    return <span />;

}

ActivityTypeTextField.defaultProps = {
    addLabel: true,
};

const DownloadFileButton = ({ source, record = {}, label }) => {
    return <DownloadButton fileUrl={record.file_url} fileName={record.file_name} />;
    // return <DownloadButton onClick={(e) => alert("Fichier facture n'est pas encore disponible")} fileName={record.file_name}/>;
}

DownloadFileButton.defaultProps = {
    addLabel: true,
};


const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

const ShowClient = ({ staticContext, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Show title={<Title />} {...props} actions={<ShowActions />}>
            <TabbedShowLayout redirect="list">
                <Tab label="Profil">
                    {/* <AvatarField source="photo_url" label="" size='160'/> */}

                    <TextField source="email" label="Email" />
                    <TextField source="last_name" label="Nom" className={classes.inlineBlock} />
                    <TextField source="first_name" label="Prénom" className={classes.inlineBlock} />
                    <div />
                    <TextField source="mobile" label="Mobile" />
                    {/* <ClientTypeField label="Type de client"/> */}
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                    {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
                    {/* <TextField source="address" label="Adresse postale"/>
            <TextField source="country" label="Pays"/>
            <TextField source="nationality" label="Nationalité"/> */}
                    <TextField source="address" label="Adresse" />
                    <TextField source="postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="city" label="Ville" className={classes.inlineBlock} />
                    <ValidateField source="free_delivery" label='Livraison gratuite' />
                    <DeliveryFeeButton />
                    <div />
                    <ValidateField source="pay_late" label='Paiement différé ?' />
                    {/* <br/> */}

                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                    {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
                    {/* <TextField source="address" label="Adresse postale"/>

            <TextField source="country" label="Pays" className={classes.inlineBlock}/>
            <TextField source="nationality" label="Nationalité" className={classes.inlineBlock}/> */}
                    {/* <TextField source="wallet.balance" label="Portefeuille(€)" className={classes.inlineBlock}/> */}

                    <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />

                    {/* <br/> */}
                    {/* <Divider/> */}
                    {/* <br/> */}
                    {/* <TextField source="activation_code" label="Code d'activation"/> */}

                    <ValidateField source="locked" label='Verrouillé' />
                </Tab>

                {/* <Tab label="Pièce d'identité">
            <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                    <Datagrid expand={<DocContentPanel/>} expandHeader={<div/>}>
                        <DocNameField label=""/>
                        
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            
            <Tab label="Wallet">
                <WalletBalanceField/>
                <WalletEditButton/>
                <br/>
                <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Historique de changement</Typography>
                <ReferenceManyField pagination={<Pagination />} reference="wallet_action" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="">
                        
                        <FormattedDateField source="create_time" label="Date" format="format_date_time" locales="fr-FR"/>
                        <TextField source="value" label="Valeur($)"/>
                        <WalletActionField source="action" label="Action"/>
                        </Datagrid>
                    </ReferenceManyField>
                
            </Tab> */}

                <Tab label="Cartes de paiement">
                    <ReferenceManyField pagination={<Pagination />} reference="card" target="user_id" addLabel={false}>
                        <Datagrid>
                            <CardNumberField label='Numéro de la carte' />
                            <ExpiryField label="Expiration" />
                            <TextField source="data.card_type" label="Type" />
                            <ValidateField source="is_default" label="Par défaut" />
                        </Datagrid>
                    </ReferenceManyField>

                </Tab>

                <Tab label="Commandes">
                    <ReferenceManyField pagination={<Pagination />} reference="order_client" target="client_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <TextField source="create_time" label="Date/heure" />
                            <TextField source="order_number" label="Nº de commande" />
                            <FormattedDateField source="pick_time" format="format_date" label="Date de livraison" />
                            <TimeSlotTextField label="Créneau de livraison" />
                            <FullNameDriverField label="Livreur" />

                            {/* <TextField source="from_address" label="Adresse de départ"/> */}
                            <TextField source="to_address" label="Adresse de livraison" />
                            <TextField source="total" label="Prix(€)" />
                            <OrderStatusField source="status" label="État" />
                            {/* <TextField source="rating_on_client" label="Note attribué par le chauffeur"/> */}
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Factures mensuelles">
                    <ReferenceManyField reference="invoice_differed" target="client_id" addLabel={false}>

                        <Datagrid>

                            {/* <TextField label="Nom" source="invoice_name"/> */}
                            <TextField label="Date de facture" source="invoice_date" />
                            <FormattedDateField label="Du jour" source="from_date" />
                            <FormattedDateField label="Au jour" source="to_date" />


                            <DownloadFileButton />
                        </Datagrid>
                    </ReferenceManyField>

                </Tab>
            </TabbedShowLayout>
        </Show>);
}


export default ShowClient;